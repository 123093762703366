import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  Grid2,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Pagination,
  Chip,
} from "@mui/material";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import { images, doctorImages } from "../../Config/images";
import "../DoctorList/DoctorList.css";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import SearchBar from "material-ui-search-bar";
import Booking from "../../componant/Booking/Booking";
import { useParams } from "react-router-dom";

// const doctors = [
//   {
//     image: images.doctorList,
//     name: "Dr. Jagdish B Patel",
//     title: "Consultant",
//     specialty: "General & GI Surgery, MS FICS",
//     experience: "30 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     image: images.doctorList,
//     name: "Dr. Neel B Patel",
//     title: "Consultant",
//     specialty: "General & GI Surgery, MS, FALS",
//     experience: "06 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     image: images.doctorList,
//     name: "Dr. Brijesh A Patel",
//     title: "Consultant",
//     specialty: "Critical Care, DA, IDCCM",
//     experience: "12 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     image: images.doctorList,
//     name: "Dr.Vrooti Patel ",
//     title: "Consultant",
//     specialty: "Critical Care, MD, IDCCM",
//     experience: "30 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     image: images.doctorList,
//     name: "Dr. Zinkal Patel",
//     title: "Consultant",
//     specialty: "Emergency Medicine, MRCEM ",
//     experience: "30 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     image: images.doctorList,
//     name: "Dr. Khushali Patel",
//     title: "Consultant",
//     specialty: "ENT, MS",
//     experience: "04 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     image: images.doctorList,
//     name: "Dr. Mukul Trivedi",
//     title: "Consultant",
//     specialty: "Onco Surgery, MS, MCh",
//     experience: "25 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     image: images.doctorList,
//     name: "Dr. Dhruv G Patel",
//     title: "Consultant",
//     specialty: "Onco Surgery, MS,MCh",
//     experience: "06 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
// ];

const ViewProfile = ({ doctors }) => {
  const { id } = useParams();

  const doctorFindWithId = doctors.find((doctor) => doctor.id === Number(id));
  // console.log(doctorFindWithId);

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Doctor Details",
      url: "/view-profile",
    },
  ];

  return (
    <>
      <div>
        <div className="header-image">
          <div className="absolute-image-container">
            <img
              src={images.contactslider}
              alt="Doctors"
              className="absolute-image"
            />
            <div className="image-text-about">
              <h1 className="text-about">Doctor Details</h1>
              <p className="text-health">Meet our specialist</p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomeBooking noMargin />
        </div>
        <div className="commit-sec mb-5">
          <div className="doctor-title mt-3">Doctor Profile</div>
          <div className="pt-5">
            <div
              className="d-flex gap-5 doct-pro-sec"
              style={{ flexWrap: "wrap" }}
            >
              <div>
                <div>
                  <img
                    className="view-profile-img"
                    src={doctorFindWithId.image}
                    alt="doctor-img"
                  />
                </div>
                <button
                  variant="contained"
                  size="small"
                  className="book-appoinment-btn mt-3 mx-4"
                >
                  Book An Appointment
                </button>
              </div>
              <div>
                <Box
                  sx={{
                    width: "650px",
                  }}
                  className="profile-box"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        className="doct-name"
                      >
                        {doctorFindWithId.name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "gray" }}>
                        {doctorFindWithId.title}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Speciality:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Chip
                        label={doctorFindWithId.specialty}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Qualification:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Chip
                          label="DA"
                          className="chip-label"
                          sx={{ borderRadius: "5px" }}
                        />
                        <Chip
                          label="IDCCM"
                          className="chip-label"
                          sx={{ borderRadius: "5px" }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Experience:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Chip
                        label={doctorFindWithId.experience}
                        style={{ fontWeight: 700 }}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Gender:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Chip
                        label={doctorFindWithId?.gender ?? "Male"}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid>

                    {/* Language Spoken */}
                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Language Spoken:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Chip
                          label="Gujarati"
                          className="chip-label"
                          sx={{ borderRadius: "5px" }}
                        />
                        <Chip
                          label="Hindi"
                          className="chip-label"
                          sx={{ borderRadius: "5px" }}
                        />
                        <Chip
                          label="English"
                          className="chip-label"
                          sx={{ borderRadius: "5px" }}
                        />
                      </Box>
                    </Grid>

                    {/* Location */}
                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Location:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Chip
                        label={doctorFindWithId.location}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid>

                    {/* Consultation Charges */}
                    <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Consultation Charges:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Chip
                        label="500"
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Booking />
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
