import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  Grid2,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Pagination,
} from "@mui/material";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import { images, doctorImages } from "../../Config/images";
import "../DoctorList/DoctorList.css";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import SearchBar from "material-ui-search-bar";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

// const doctors = [
//   {
//     id: 1,
//     name: "Dr. Jagdish B Patel",
//     image: doctorImages.DrJagdishBPatel,
//     title: "Consultant",
//     specialty: "General & GI Surgery, MS FICS",
//     experience: "30 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 2,
//     name: "Dr. Neel B Patel",
//     image: doctorImages.DrNeelPatel,
//     title: "Consultant",
//     specialty: "General & GI Surgery, MS, FALS",
//     experience: "06 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 3,
//     name: "Dr. Brijesh A Patel",
//     image: doctorImages.DrBrijeshPatel,
//     title: "Consultant",
//     specialty: "Critical Care, DA, IDCCM",
//     experience: "12 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 4,
//     name: "Dr. Vrooti Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Critical Care, MD, IDCCM",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 5,
//     name: "Dr. Zinkal Patel",
//     image: doctorImages.DrZinkalPatel,
//     title: "Consultant",
//     specialty: "Emergency Medicine, MRCEM",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 6,
//     name: "Dr. Khushali Patel",
//     image: doctorImages.DrKhushaliPatel,
//     title: "Consultant",
//     specialty: "ENT, MS",
//     experience: "04 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 7,
//     name: "Dr. Mukul Trivedi",
//     image: doctorImages.DrMukulTrivedi,
//     title: "Consultant",
//     specialty: "Onco Surgery, MS, MCh",
//     experience: "25 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 8,
//     name: "Dr. Dhruv G Patel",
//     image: doctorImages.DrDhruvPatel,
//     title: "Consultant",
//     specialty: "Onco Surgery, MS, MCh",
//     experience: "06 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 9,
//     name: "Dr. Jayesh J Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Onco Surgery, MS",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 10,
//     name: "Dr. Roopesh Mody",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Onco Surgery, MS, MCh",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 11,
//     name: "Dr. Mitesh Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Oral & Maxillofacial Surgery, MDS",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 12,
//     name: "Dr. Hasmukh Agrawal",
//     image: doctorImages.DrHasmukhAgrawal,
//     title: "Consultant",
//     specialty: "Obstetrics & Gynaecology, MS",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 13,
//     name: "Dr. Rushabh Agrawal",
//     image: doctorImages.DrRushabhAgrawal,
//     title: "Consultant",
//     specialty: "Obstetrics & Gynaecology, MS",
//     experience: "04 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 14,
//     name: "Dr. Meet Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Obstetrics & Gynaecology, MS",
//     experience: "05 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 15,
//     name: "Dr. Varun Patel",
//     image: doctorImages.DrVarunPatel,
//     title: "Consultant",
//     specialty: "Pulmonary Medicine, DTCD, DNB",
//     experience: "14 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 16,
//     name: "Dr. Rushi Desai",
//     image: doctorImages.DrRushiDesai,
//     title: "Consultant",
//     specialty: "Pulmonary Medicine, MD, DM",
//     experience: "05 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 17,
//     name: "Dr. Shrinesh Agrawal",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Pulmonary Medicine, MD",
//     experience: "03 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 18,
//     name: "Dr. Kamlesh Shah",
//     image: doctorImages.DrKamleshShah,
//     title: "Consultant",
//     specialty: "Urology, MS, DNB",
//     experience: "15 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 19,
//     name: "Dr. Keval Patel",
//     image: doctorImages.DrKevalPatel,
//     title: "Consultant",
//     specialty: "Urology, MS, DNB",
//     experience: "15 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 20,
//     name: "Dr. Anand Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Bariatrics Surgery, MS, DNB",
//     experience: "10 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 21,
//     name: "Dr. Chandrika Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Anaesthesia, MD",
//     experience: "30 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 22,
//     name: "Dr. Umesh R Hadvani",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Anaesthesia, DA",
//     experience: "15 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 23,
//     name: "Dr. Amit Agrawal",
//     image: doctorImages.DrAmitAgrawal,
//     title: "Consultant",
//     specialty: "Orthopaedics, MS",
//     experience: "25 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 24,
//     name: "Dr. Maharshi Bhatt",
//     image: doctorImages.DrMaharshiBhatt,
//     title: "Consultant",
//     specialty: "Orthopaedics, MS",
//     experience: "25 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 25,
//     name: "Dr. Tejas Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Neuro Surgery, MS, MCh",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 26,
//     name: "Dr. Mansi Shah",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Dermatology, MD, DVL",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 27,
//     name: "Dr. Vishal Vora",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Plastic Surgery, DNB",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 28,
//     name: "Dr. Dhaval Bhadja (Patel)",
//     image: doctorImages.DrDhavalBhadaja,
//     title: "Consultant",
//     specialty: "General Medicine, DNB",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 29,
//     name: "Dr. Ravi Bokarvadia (Patel)",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Gastrology, MD, DNB",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 30,
//     name: "Dr. Yash Gangadia",
//     image: doctorImages.DrYashGangadia,
//     title: "Consultant",
//     specialty: "Gastrology, MD, DNB",
//     experience: "7 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 31,
//     name: "Dr. Neil Palkhiwala",
//     image: doctorImages.DrNeilPalkhivala,
//     title: "Consultant",
//     specialty: "Gastrology, MD, DM",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 32,
//     name: "Dr. Chirag Patel",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Cardiology, MD, DM",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 33,
//     name: "Dr. Jignesh Patel",
//     image: doctorImages.DrJigneshPatel,
//     title: "Consultant",
//     specialty: "Cardiology, MD, DM",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 34,
//     name: "Dr. Jigar Anandjiwala",
//     image: doctorImages.DrJigarAnandjiwala,
//     title: "Consultant",
//     specialty: "Spine Surgery, D.ORTHO",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 35,
//     name: "Dr. Rinku Trivedi",
//     image: doctorImages.ProfileImageMissing,
//     title: "Consultant",
//     specialty: "Dental, BDS",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 36,
//     image: doctorImages.DrBhagyadhanPatel,
//     name: "Dr. Bhagyadhan A Patel",
//     title: "Consultant",
//     specialty: "Neurology, MD, DNB",
//     experience: "11 Years",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 37,
//     image: doctorImages.DrChintanPrajapati,
//     name: "Dr. Chintan Prajapati",
//     title: "Consultant",
//     specialty: "Neurology, MD, DM",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
//   {
//     id: 38,
//     name: "Dr. Bhargav Prajapati",
//     image: doctorImages.DrBhargavPrajapati,
//     title: "Consultant",
//     specialty: "Neurology, MD, DM",
//     experience: "N/A",
//     location: "Vastral, Ahmedabad - India",
//   },
// ];

const DoctorList = ({ doctors }) => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Doctor List",
      url: "/doctor-list",
    },
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    console.log(event); // Check the event structure
    if (event && event.target) {
      setSearchTerm(event.target.value); // Ensure target exists
    }
  };

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      borderColor: "var(--secondary-color)",
      borderWidth: "1px",
      borderStyle: "solid",
      marginTop: "30px",
    },
    "& .Mui-selected": {
      borderColor: "var(--secondary-color)",
      backgroundColor: "var(--secondary-color)  !important",
      color: "var(--white)",
    },
  }));

  const handleSearch = () => {
    // doSomethingWith(value);
  };
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="header-image">
          <div className="absolute-image-container">
            <img
              src={images.contactslider} // Replace with your image source
              alt="Doctors"
              className="absolute-image"
            />
            <div className="image-text-about">
              <h1 className="text-about">Doctor List</h1>
              <p className="text-health">
                Our Team of Specialists <br />
                Delivering Excellence in Every Field
              </p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomeBooking noMargin />
        </div>
        <div className="commit-sec">
          <div className="doctor-title mt-3">Doctor List</div>
          <Grid container spacing={2} className="mt-4">
            <Grid item xs={12} md={6}>
              {" "}
              <SearchBar
                value={searchTerm}
                onChange={(newValue) => setSearchTerm(newValue)} // Handle user typing
                onRequestSearch={() => handleSearchChange()}
                onCancelSearch={() => setSearchTerm("")}
                placeholder="Search for Doctors, Specialties and Hospitals"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ textAlign: "end" }}
              className="filter-drop"
            >
              <FormControl
                style={{ width: "200px", marginRight: "20px" }}
                className="filterHospital"
              >
                <InputLabel id="demo-simple-select-label">
                  Filer By Hospital
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Filer By Hospital"
                >
                  {/* <MenuItem value={10}>Filer By Hospital</MenuItem>
                  <MenuItem value={20}>Filer By Hospital</MenuItem>
                  <MenuItem value={30}>Filer By Hospital</MenuItem> */}
                </Select>
              </FormControl>
              <FormControl
                style={{ width: "200px" }}
                className="filterSpecialist"
              >
                <InputLabel id="demo-simple-select-label">
                  Filter By Specialist
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Filter By Specialist"
                >
                  {/* <MenuItem value={10}>Filter By Specialist</MenuItem>
                  <MenuItem value={20}>Filter By Specialist</MenuItem>
                  <MenuItem value={30}>Filter By Specialist</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="pt-5">
            <Grid container spacing={3}>
              {doctors.map((doctor, index) => {
                return (
                  <Grid item xs={12} sm={6} md={6} key={index} className="pt-5">
                    <div
                      className="d-flex gap-3 doct-details"
                      style={{ flexWrap: "wrap" }}
                    >
                      <div>
                        <img
                          src={doctor.image}
                          alt=""
                          style={{ width: "210px" }}
                          className="doct-img"
                        />
                      </div>
                      <div>
                        <h6>{doctor.name}</h6>
                        <p className="mt-3">{doctor.title}</p>
                        <p>{doctor.specialty}</p>
                        <p>
                          Experience: <b>{doctor.experience}</b>
                        </p>
                        <p>
                          <img src={images.userLocation} alt="" />{" "}
                          {doctor.location}
                        </p>
                        <div
                          className="mt-3 d-flex gap-2"
                          style={{ flexWrap: "wrap" }}
                        >
                          <button
                            variant="outlined"
                            size="small"
                            className="view-profile-btn"
                            onClick={() =>
                              navigate(`/view-profile/${doctor?.id}`)
                            }
                          >
                            View Profile
                          </button>
                          <button
                            variant="contained"
                            size="small"
                            className="book-appoinment-btn"
                            onClick={() =>
                              navigate(`/view-profile/${doctor?.id}`)
                            }
                          >
                            Book An Appointment
                          </button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <br />
          {/* <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center" // Centers the content horizontally
            className="mt-5 mb-5 d-flex pagination-stack"
            sx={{
              padding: "16px", // Optional padding for spacing
              borderRadius: "8px", // Optional rounded corners
            }}
          >
            <CustomPagination
              count={1}
              sx={{
                "& .MuiPaginationItem-ellipsis": {
                  color: "#01ADEC", // Apply color to the ellipsis (if any)
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#01ADEC", // Background color when a page is selected
                  color: "#fff", // White text when selected
                },
              }}
            />
          </Stack> */}
        </div>
      </div>
    </>
  );
};

export default DoctorList;
