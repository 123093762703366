import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../componant/Header/Header";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AbouUs/AboutUs";
import Specialties from "../pages/Specialties/Specialties";
import Contact from "../pages/Contact/Contact";
import Navbar from "../componant/Navbar/Navbar";
import Footer from "../componant/Footer/Footer";
import DoctorList from "../pages/DoctorList/DoctorList";
import ViewProfile from "../pages/ViewProfile/ViewProfile";
import { doctorImages } from "../Config/images";

const Routers = () => {
  const doctors = [
    {
      id: 1,
      name: "Dr. Jagdish B Patel",
      image: doctorImages.DrJagdishBPatel,
      title: "Consultant",
      specialty: "General & GI Surgery, MS FICS",
      experience: "30 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 2,
      name: "Dr. Neel B Patel",
      image: doctorImages.DrNeelPatel,
      title: "Consultant",
      specialty: "General & GI Surgery, MS, FALS",
      experience: "06 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 3,
      name: "Dr. Brijesh A Patel",
      image: doctorImages.DrBrijeshPatel,
      title: "Consultant",
      specialty: "Critical Care, DA, IDCCM",
      experience: "12 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 4,
      name: "Dr. Vrooti Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Critical Care, MD, IDCCM",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 5,
      name: "Dr. Zinkal Patel",
      image: doctorImages.DrZinkalPatel,
      title: "Consultant",
      specialty: "Emergency Medicine, MRCEM",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 6,
      name: "Dr. Khushali Patel",
      image: doctorImages.DrKhushaliPatel,
      title: "Consultant",
      specialty: "ENT, MS",
      experience: "04 Years",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 7,
      name: "Dr. Mukul Trivedi",
      image: doctorImages.DrMukulTrivedi,
      title: "Consultant",
      specialty: "Onco Surgery, MS, MCh",
      experience: "25 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 8,
      name: "Dr. Dhruv G Patel",
      image: doctorImages.DrDhruvPatel,
      title: "Consultant",
      specialty: "Onco Surgery, MS, MCh",
      experience: "06 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 9,
      name: "Dr. Jayesh J Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Onco Surgery, MS",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 10,
      name: "Dr. Roopesh Mody",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Onco Surgery, MS, MCh",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 11,
      name: "Dr. Mitesh Patel",
      image: doctorImages.DrMiteshPatel,
      title: "Consultant",
      specialty: "Oral & Maxillofacial Surgery, MDS",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 12,
      name: "Dr. Hasmukh Agrawal",
      image: doctorImages.DrHasmukhAgrawal,
      title: "Consultant",
      specialty: "Obstetrics & Gynaecology, MS",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 13,
      name: "Dr. Rushabh Agrawal",
      image: doctorImages.DrRushabhAgrawal,
      title: "Consultant",
      specialty: "Obstetrics & Gynaecology, MS",
      experience: "04 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 14,
      name: "Dr. Meet Patel",
      image: doctorImages.DrMeetPatel,
      title: "Consultant",
      specialty: "Obstetrics & Gynaecology, MS",
      experience: "05 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 15,
      name: "Dr. Varun Patel",
      image: doctorImages.DrVarunPatel,
      title: "Consultant",
      specialty: "Pulmonary Medicine, DTCD, DNB",
      experience: "14 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 16,
      name: "Dr. Rushi Desai",
      image: doctorImages.DrRushiDesai,
      title: "Consultant",
      specialty: "Pulmonary Medicine, MD, DM",
      experience: "05 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 17,
      name: "Dr. Shrinesh Agrawal",
      image: doctorImages.DrShrineshAgrawal,
      title: "Consultant",
      specialty: "Pulmonary Medicine, MD",
      experience: "03 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 18,
      name: "Dr. Kamlesh Shah",
      image: doctorImages.DrKamleshShah,
      title: "Consultant",
      specialty: "Urology, MS, DNB",
      experience: "15 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 19,
      name: "Dr. Keval Patel",
      image: doctorImages.DrKevalPatel,
      title: "Consultant",
      specialty: "Urology, MS, DNB",
      experience: "15 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 20,
      name: "Dr. Anand Patel",
      image: doctorImages.DrAnandPatel,
      title: "Consultant",
      specialty: "Bariatrics Surgery, MS, DNB",
      experience: "10 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 21,
      name: "Dr. Chandrika Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Anaesthesia, MD",
      experience: "30 Years",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 22,
      name: "Dr. Umesh R Hadvani",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Anaesthesia, DA",
      experience: "15 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 23,
      name: "Dr. Amit Agrawal",
      image: doctorImages.DrAmitAgrawal,
      title: "Consultant",
      specialty: "Orthopaedics, MS",
      experience: "25 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 24,
      name: "Dr. Maharshi Bhatt",
      image: doctorImages.DrMaharshiBhatt,
      title: "Consultant",
      specialty: "Orthopaedics, MS",
      experience: "25 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 25,
      name: "Dr. Tejas Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Neuro Surgery, MS, MCh",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 26,
      name: "Dr. Mansi Shah",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Dermatology, MD, DVL",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 27,
      name: "Dr. Vishal Vora",
      image: doctorImages.DrVishalVora,
      title: "Consultant",
      specialty: "Plastic Surgery, DNB",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 28,
      name: "Dr. Dhaval Bhadja (Patel)",
      image: doctorImages.DrDhavalBhadaja,
      title: "Consultant",
      specialty: "General Medicine, DNB",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 29,
      name: "Dr. Ravi Bokarvadia (Patel)",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Gastrology, MD, DNB",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 30,
      name: "Dr. Yash Gangadia",
      image: doctorImages.DrYashGangadia,
      title: "Consultant",
      specialty: "Gastrology, MD, DNB",
      experience: "7 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 31,
      name: "Dr. Neil Palkhiwala",
      image: doctorImages.DrNeilPalkhivala,
      title: "Consultant",
      specialty: "Gastrology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 32,
      name: "Dr. Chirag Patel",
      image: doctorImages.DrChiragMPatel,
      title: "Consultant",
      specialty: "Cardiology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 33,
      name: "Dr. Jignesh Patel",
      image: doctorImages.DrJigneshPatel,
      title: "Consultant",
      specialty: "Cardiology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 34,
      name: "Dr. Jigar Anandjiwala",
      image: doctorImages.DrJigarAnandjiwala,
      title: "Consultant",
      specialty: "Spine Surgery, D.ORTHO",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 35,
      name: "Dr. Rinku Trivedi",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Dental, BDS",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 36,
      image: doctorImages.DrBhagyadhanPatel,
      name: "Dr. Bhagyadhan A Patel",
      title: "Consultant",
      specialty: "Neurology, MD, DNB",
      experience: "11 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 37,
      image: doctorImages.DrChintanPrajapati,
      name: "Dr. Chintan Prajapati",
      title: "Consultant",
      specialty: "Neurology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 38,
      name: "Dr. Bhargav Prajapati",
      image: doctorImages.DrBhargavPrajapati,
      title: "Consultant",
      specialty: "Neurology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
  ];

  return (
    <Router>
      <Header />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/specialties" element={<Specialties />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/doctor-list" element={<DoctorList doctors={doctors} />} />
        <Route
          path="/view-profile/:id"
          element={<ViewProfile doctors={doctors} />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default Routers;
